import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../interfaces/category';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Brand } from '../interfaces/brand';
import { Product } from '../interfaces/product';
import { ProductsList } from '../interfaces/list';
import { SerializedFilterValues } from '../interfaces/filter';
import { ActivatedRoute, Router } from '@angular/router';
import {
    getBestsellers,
    getFeatured,
    getLatestProducts,
    getProduct,
    getRelatedProducts,
    getSpecialOffers,
    getTopRated,
    getShopCategoriesBySlugs,
    getShopCategoriesTree,
    getShopCategory,
    getBrands,
    getProductsList
} from '../../../fake-server';
//import { combineAll } from 'rxjs/operators';
//import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';

export interface ListOptions {
    page?: number;
    limit?: number;
    sort?: string;
    filters?: object;
    filterValues?: SerializedFilterValues;
    zoekterm?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ShopService {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient
    ) {}

    /**
     * Returns category object by slug.
     *
     * @param slug - Unique human-readable category identifier.
     */
    getCategory(
        merk: string,
        slug: string,
        productType: string
    ): Observable<Category> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/categories/power-tools.json
         *
         * where:
         * - power-tools = slug
         */
        //    console.log(slug);
        const params: { [param: string]: string } = {};

        //console.log("AAA");
        //const url = this.router.url.split("/");
        //console.log(url);

        /*  if (window.location.pathname) {

             //const url = this.router.url.split("/");
             //console.log(url);
             //console.log("____");

             const url = window.location.pathname.substr(1);

             if (url.includes("/") ) {

              var res = url.split("/");

              params.type = res[0];

                params.serie = slug;
                params.merk = res[1];

             } else {

              params.type = url;
              params.serie = "";

        if (slug) {
              params.merk = slug;
          }

             }

          } */

        if (merk) {
            params.merk = merk;
        }

        if (slug) {
            params.serie = encodeURIComponent(slug);
        }

        if (productType) {
            params.type = productType;
        }

        if (slug == null) {
            slug = productType;
        }

        //console.log(url[1]);
        //console.log(window.location.href);
        return this.http.get<Category>(
            `https://api.voordeelvloeren.nl/categories/${slug}.json`,
            { params }
        );

        // This is for demonstration purposes only. Remove it and use the code above.
        //    console.log(JSON.stringify(getShopCategory(slug)));

        //   const employees = getShopCategory(slug);

        /*var employees = {
            kiran: { age:30,salary:10000},
            john: { age:35,salary:15000},
            Tom: { age: 21, salary:5000}
        } */

        /*

                const data = getShopCategory(slug);

                data.subscribe({
                    next(response) { console.log(response); },
                    error(err) { console.error('Error: ' + err); },
                    complete() { console.log('Completed'); }
                  });
        */

        //      console.log(slug);
        //      console.log("asd");
        //      return getShopCategory(slug);
    }

    /**
     * Returns a category tree.
     *
     * @param parent - If a parent is specified then its descendants will be returned.
     * @param depth  - Maximum depth of category tree.
     */
    getCategories(
        parent: Partial<Category> = null,
        depth = 0
    ): Observable<Category[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/categories.json?parent=latest-news&depth=1
         *
         * where:
         * - parent = parent.slug
         * - depth  = depth
         */
        // const params: {[param: string]: string} = {
        //     parent: parent.slug,
        //     depth: depth.toString(),
        // };
        //
        // return this.http.get<Category[]>('https://example.com/api/shop/categories.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        console.log('asd2');
        return getShopCategoriesTree(parent ? parent.slug : null, depth);
    }

    /**
     * Returns an array of the specified categories.
     *
     * @param slugs - Array of slugs.
     * @param depth - Maximum depth of category tree.
     */
    getCategoriesBySlug(slugs: string[], depth = 0): Observable<Category[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/categories.json?slugs=power-tools,measurement&depth=1
         *
         * where:
         * - slugs = slugs.join(',')
         * - depth = depth
         */
        // const params: {[param: string]: string} = {
        //     slugs: slugs.join(','),
        //     depth: depth.toString(),
        // };
        //
        // return this.http.get<Category[]>('https://example.com/api/shop/categories.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.

        return getShopCategoriesBySlugs(slugs, depth);
    }

    /**
     * Returns paginated products list.
     * If categorySlug is null then a list of all products should be returned.
     *
     * @param categorySlug    - Unique human-readable category identifier.
     * @param merkSlug        - Unique human-readable category identifier.
     * @param options         - Options.
     * @param options.page    - Page number (optional).
     * @param options.limit   - Maximum number of items returned at one time (optional).
     * @param options.sort    - The algorithm by which the list should be sorted (optional).
     * @param options.filters - An object whose keys are filter slugs and values ​​are filter values (optional).
     */
    getProductsList(
        merkSlug: string | null,
        categorySlug: string | null,
        options: ListOptions,
        productType: string | null
    ): Observable<ProductsList> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products.json?category=screwdriwers&page=2&limit=12&sort=name_desc&filter_price=500-1000
         *
         * where:
         * - category     = categorySlug
         * - page         = options.page
         * - limit        = options.limit
         * - sort         = options.sort
         * - filter_price = options.filters.price
         */

        //console.log('$$$$$$' + JSON.stringify(options));

        const params: { [param: string]: string } = {};

        params.serie = encodeURIComponent(categorySlug);
        params.merk = merkSlug;
        params.type = productType;

        /*   const url = window.location.pathname.substr(1);

   //        params.heleurl = url;

           if (url.includes("/") ) {

               var res = url.split("/");

           params.type = res[0];


            if (categorySlug) {
                params.serie = categorySlug;
            }

            params.merk = res[1];

           } else {

               if (categorySlug) {
                   params.merk = categorySlug;
               }

               params.type = productType;
               params.serie = "";

           }*/
        //console.log('!!!');

        //console.log(options);

        if ('zoekterm' in options) {
            params.zoekterm = options.zoekterm;
        } else {
            params.zoekterm = 'onbekend';
        }

        if ('page' in options) {
            params.page = options.page.toString();
        } else {
            params.page = '1';
        }

        if ('limit' in options) {
            params.limit = options.limit.toString();
        } else {
            params.limit = '30';
        }

        if ('sort' in options) {
            params.sort = options.sort;
        } else {
            params.sort = 'default';
        }

        //        if (solrProducts) {

        //      }
        /*    Object.keys(options).forEach(function(key) {
                console.log(key, options[key]);
               console.log("----");

              }); */

        //                  console.log(options.filterValues.model);

        //Object.keys(options.filters).forEach(slug => params[`filter_${slug}`] = options.filters[slug]);

        if (typeof options.filterValues != 'undefined') {
            Object.keys(options.filterValues).forEach(
                (slug) =>
                    (params[`filter_${slug}`] = options.filterValues[slug])
            );
        }

        // return this.http.get<ProductsList>('https://example.com/api/products.json', {params});
        //console.log('asdasdasd');

        //console.log(params);

        const headers = new HttpHeaders().set(
            'Content-Type',
            'application/json'
        );

        //this.http.get<ProductsList>('https://www.voordeelvloeren.nl/api/products.php', {params});
        return this.http.get<ProductsList>(
            'https://api.voordeelvloeren.nl/productlist.php',
            { params, headers }
        );

        // This is for demonstration purposes only. Remove it and use the code above.

        //   return getProductsList(categorySlug, options);
    }

    getProduct(productSlug: string): Observable<Product> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products/electric-planer-brandix-kl370090g-300-watts.json
         *
         * where:
         * - electric-planer-brandix-kl370090g-300-watts = productSlug
         */
        //console.log("GET PRODUCT1");
        // console.log('JAAa' + productSlug);

        return this.http.get<Product>(
            `https://api.voordeelvloeren.nl/product/${productSlug}.json`
        );

        // This is for demonstration purposes only. Remove it and use the code above.

        //    console.log(JSON.stringify(getProduct(productSlug)));

        //    return getProduct(productSlug);
    }

    /**
     * Returns popular brands.
     */
    getPopularBrands(): Observable<Brand[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/brands/popular.json
         */
        // return this.http.get<Brand[]>('https://example.com/api/shop/brands/popular.json');

        // This is for demonstration purposes only. Remove it and use the code above.
        return getBrands();
    }

    getBestsellers(limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/bestsellers.json?limit=3
         *
         * where:
         * - limit = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/bestsellers.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.

        return getBestsellers(limit);
    }

    getTopRated(limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/top-rated.json?limit=3
         *
         * where:
         * - limit = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/top-rated.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getTopRated(limit);
    }

    getSpecialOffers(limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/special-offers.json?limit=3
         *
         * where:
         * - limit = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/special-offers.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getSpecialOffers(limit);
    }

    getFeaturedProducts(
        category: string = null,
        limit: number = null
    ): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/featured.json?category=screwdrivers&limit=3
         *
         * where:
         * - category = categorySlug
         * - limit    = limit
         */
        const params: { [param: string]: string } = {};

        if (category) {
            params.category = category;
        }
        if (limit) {
            params.limit = limit.toString();
        }

        return this.http.get<Product[]>(
            'https://api.voordeelvloeren.nl/featured.php',
            { params }
        );

        // This is for demonstration purposes only. Remove it and use the code above.
        //return getFeatured(categorySlug, limit);
    }

    getLastViewedProducts(
        category: string = null,
        limit: number = null,
        lastViewed = []
    ): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/featured.json?category=screwdrivers&limit=3
         *
         * where:
         * - category = categorySlug
         * - limit    = limit
         */
        const params: { [param: string]: string } = {};

        if (category) {
            params.category = category;
        }
        if (limit) {
            params.limit = limit.toString();
        }
        if (lastViewed) {
            params.lastviewed = lastViewed.toString();
        }

        return this.http.get<Product[]>(
            'https://api.voordeelvloeren.nl/last-viewed.php',
            { params }
        );

        // This is for demonstration purposes only. Remove it and use the code above.
        //return getFeatured(categorySlug, limit);
    }

    getSameProducts(productID: string = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/featured.json?category=screwdrivers&limit=3
         *
         * where:
         * - category = categorySlug
         * - limit    = limit
         */
        const params: { [param: string]: string } = {};

        if (productID) {
            params.category = productID;
        }

        return this.http.get<Product[]>(
            'https://api.voordeelvloeren.nl/same.php',
            { params }
        );

        // This is for demonstration purposes only. Remove it and use the code above.
        //return getFeatured(categorySlug, limit);
    }

    getLatestProducts(
        categorySlug: string = null,
        limit: number = null
    ): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/latest.json?category=screwdrivers&limit=3
         *
         * where:
         * - category = categorySlug
         * - limit    = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (category) {
        //     params.category = category;
        // }
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/latest.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getLatestProducts(categorySlug, limit);
    }

    getRelatedProducts(product: Partial<Product>): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/related.json?for=water-tap
         *
         * where:
         * - for = product.slug
         */
        // const params: {[param: string]: string} = {
        //     for: product.slug,
        // };
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/related.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getRelatedProducts(product);
    }
}
